import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GwcScript = () => {
  return (
    <section className="case-study cs-brief">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Script</h2>
                    <div className="sub-content mt-5">
                        <p>The team of scriptwriters in WowMakers created an amazing, compelling and informative script for the client in a way that will move their target audience to take action.</p>
                        <p>The script was created to explain the offerings of GWC in the way they wanted while keeping the content informative with a casual and conversational tone, and minimal or no use of jargon words, so that it could reach the right audience.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default GwcScript
