import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GwcConclution = () => {
  return (
    <section className="case-study cs-conclution">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Conclusion</h2>
                    <div className="sub-content mt-5">
                        <p>Do you need the power of explainer video to propel your business to more conversions?</p>
                        <p>Our team of creative storytellers will break down complicated and boring ideas, and convert them into simple, short, and engaging explainer videos that will attract, educate and convert the audience into your customers.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default GwcConclution
