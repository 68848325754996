import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/gwc-logistics.scss"
import GwcMain from "../../components/case-studies/gwc-logistics/gwcMain"
import GwcInfo from "../../components/case-studies/gwc-logistics/gwcInfo"
import GwcAbout from "../../components/case-studies/gwc-logistics/gwcAbout"
import GwcObjectives from "../../components/case-studies/gwc-logistics/gwcObjectives"
import GwcProcess from "../../components/case-studies/gwc-logistics/gwcProcess"
import GwcBrief from "../../components/case-studies/gwc-logistics/gwcBrief"
import GwcConcept from "../../components/case-studies/gwc-logistics/gwcConcept"
import GwcScript from "../../components/case-studies/gwc-logistics/gwcScript"
import GwcStoryBoard from "../../components/case-studies/gwc-logistics/gwcStoryBoard"
import GwcAnimation from "../../components/case-studies/gwc-logistics/gwcAnimation"
import GwcSfxDesign from "../../components/case-studies/gwc-logistics/gwcSfxDesign"
import GwcImpact from "../../components/case-studies/gwc-logistics/gwcScript copy"
import GwcConclution from "../../components/case-studies/gwc-logistics/gwcConclution"
import GwcMoreCs from "../../components/case-studies/gwc-logistics/gwcMoreCs"
import DirectContact from "../../components/global/directContact"
import csGwcFeaturedImage from "../../../static/case-studies/gwc-logistics/gwc-social-share.png"
export const Head = () => (
  <>
    <SeoHead
      ogImage={csGwcFeaturedImage}
      title="GWC Case Study | 2D Animation Explainer Video | Logistics"
      description="Learn how we helped Qatar’s leading logistics provider market its grand solutions with a creative explainer video to boost their new product launch."
    />
  </> 
)

const GwcLogistics = () => {
    return (
      <Layout>
        <Seo
          bid="cs-gwc-logistics"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <GwcMain />
        <GwcInfo />
        <GwcAbout />
        <GwcObjectives />
        <GwcProcess />
        <GwcBrief />
        <GwcConcept />
        <GwcScript />
        <GwcStoryBoard />
        <GwcAnimation />
        <GwcSfxDesign />
        <GwcImpact />
        <GwcConclution />
        <GwcMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default GwcLogistics
