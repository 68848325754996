import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GwcAnimation = () => {
  return (
    <section className="case-study cs-animation">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center mb-5">Animation and Voice Over</h2>
                    <StaticImage src="../../../images/case-studies/gwc-logistics/animation-and-voice-over.png" alt="GWC Logistics Explainer Video case study | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={800} height={450}/>
                    <div className="sub-content mt-5">
                        <p>A video that has some human elements helps the viewers to better understand and connect with the message conveyed.</p>
                        <p>Thus, the video was created with animated humans as characters along with subtle music, and a crisp and clear voice-over that added to its charm. Keeping the screen neat and simple, the video was able to keep the viewers engaged without distracting them by any means.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default GwcAnimation
