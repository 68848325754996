import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GwcSfxDesign = () => {
  return (
    <section className="case-study cs-sfx-design">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">SFX Design</h2>
                    <div className="sub-content mt-5">
                        <p>SFX plays a major role in the video-making process, as it helps to complete the video in style.</p>
                        <p>The sound design we did for the video was on the dot, it tied up all the visuals in the best way possible while incorporating the voice-over without drowning it out. Overall, the efforts created a splendid audio-visual experience for the viewers - a real treat to their eyes and ears!</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default GwcSfxDesign
