import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GwcConcept = () => {
  return (
    <section className="case-study cs-concept">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Concept</h2>
                    <div className="sub-content mt-5">
                        <p>Once the client’s ideas were collected and put to paper, the video concept was shared with them.</p>
                        <p>For GWC Logistics, the video concept focused on small to medium-sized business owners. Thus, simple and relatable images were used with a storyline that could keep the viewers engaged and interested.</p>
                    </div>
                    <StaticImage src="../../../images/case-studies/gwc-logistics/concept.png" alt="GWC Logistics Explainer Video case study | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={800} height={450}/>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default GwcConcept
