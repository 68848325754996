import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GwcProcess = () => {
  return (
    <section className="case-study cs-process">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Our process</h2>
                    <div className="sub-content mt-5">
                        <ul>
                            <li>The Brief and Kick-off Call</li>
                            <li>Concept</li>
                            <li>Script</li>
                            <li>Storyboard and Style Frames</li>
                            <li>Animation and Voice Over</li>
                            <li>SFX design</li>
                        </ul>                        
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default GwcProcess
