import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GwcImpact = () => {
  return (
    <section className="case-study cs-impact">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Impact</h2>
                    <div className="sub-content mt-5">
                        <p>The video had garnered thousands of views on LinkedIn as well as on other social media platforms on the day it was launched. It also cracked the tag of ‘the post with the most engagement within 24 hours’ and that was indeed an achievement to count!</p>
                        <p>Our explainer video was able to achieve the objective of creating a feeling of consideration of Al Wukair Logistics Park among the target audience in the way the client needed while standing out from the rest of their competitors.</p>
                        <p>Being very happy with the first project we did for them, the client came back to us with further offerings. Thus, our team is currently working on more of their projects, so stay tuned!</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default GwcImpact
