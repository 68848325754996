import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GwcAbout = () => {
  return (
    <section className="case-study">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <Row className="align-items-center">
                        <Col lg={8}>
                            <div className="about-cs-product">
                                <p>Al Wukair Logistics Park - an initiative of GWC is one of their milestone projects that provides industrial workshops, storage units, showrooms, and open yards to suit the needs of micro, small, and medium-sized businesses through a single one-page contract and easy processes. Through this project, they aim to contribute to the Qatar National Vision 2030.</p>
                                <p>The GWC team got in touch with us to create an explainer video that could explain their offerings and thereby encourage consideration in their target audience.</p>                  
                            </div>                            
                        </Col>
                        <Col lg={4}>
                            <div className="about-company">
                                <h4>Company</h4>
                                <p>GWC is the leading provider of logistics and supply chain solutions in the State of Qatar. The company offers the full spectrum of high-quality solutions to a variety of industry verticals. The company is backed by a team of innovative experts and supported by state-of-the-art IT systems and logistical infrastructure, including the largest logistics hub in Qatar, the Logistics Village Qatar.</p>
                                <p><a href="https://gwclogistics.com/" target="_blank" rel="noreferrer">https://gwclogistics.com/</a></p>
                                <StaticImage src="../../../images/case-studies/gwc-logistics/gwc-logo.png" alt="GWC Logistics Logo | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={313} height={153}/>
                            </div>                            
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>        
  )
}

export default GwcAbout
