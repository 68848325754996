import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GwcObjectives = () => {
  return (
    <section className="case-study cs-objective">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Objectives</h2>
                    <div className="sub-content mt-5">
                        <ul>
                            <li>Create content that could easily be understood and perceived by the target audience.</li>
                            <li>Explain the offerings to the target audience to encourage them to consider it.</li>
                            <li>Establish their presence on social media through this explainer video with the CTA - ‘Contact us’.</li>
                        </ul>                        
                    </div>
                    <Row>
                        <Col lg={6}>
                            <StaticImage src="../../../images/case-studies/gwc-logistics/gwc-objectives-1.png" alt="GWC Logistics Explainer Video case study | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={800} height={450}/>
                        </Col>
                        <Col lg={6}>
                            <StaticImage src="../../../images/case-studies/gwc-logistics/gwc-objectives-2.png" alt="GWC Logistics Explainer Video case study | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={800} height={450}/>
                        </Col>
                        <Col lg={12} className="mt-3">
                            <StaticImage src="../../../images/case-studies/gwc-logistics/gwc-objectives-3.png" alt="GWC Logistics Explainer Video case study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={450}/>
                        </Col>                                                
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default GwcObjectives
