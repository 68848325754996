import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GwcBrief = () => {
  return (
    <section className="case-study cs-brief">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">The Brief and Kick-off Call</h2>
                    <div className="sub-content mt-5">
                        <p>According to the process we follow, the client was asked to fill out a questionnaire that asks questions about their background, objectives, requirements, and expectations.</p>
                        <p>Based on the data collected and the discussions that followed we were able to understand their objectives and parameters and work accordingly by giving emphasis on quality and clarity, and highlighting the key points.</p>
                        <p>At this stage, ideas were shared and discussed amongst us and the client in order to create a storyline draft of the video.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default GwcBrief
