import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const GwcStoryBoard = () => {
  return (
    <section className="case-study cs-storyboard">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center mb-5">Storyboard and Style Frames</h2>
                    <Row>
                        <Col lg={12}>
                            <StaticImage src="../../../images/case-studies/gwc-logistics/storyboard.jpeg" alt="GWC Logistics Explainer Video case study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={450}/>
                        </Col>
                        <Col lg={12} className="mt-3">
                            <StaticImage src="../../../images/case-studies/gwc-logistics/style-frames-1.png" alt="GWC Logistics Explainer Video case study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={450}/>
                        </Col>
                        <Col lg={12} className="mt-3">
                            <StaticImage src="../../../images/case-studies/gwc-logistics/style-frames-2.png" alt="GWC Logistics Explainer Video case study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={450}/>
                        </Col>
                        <Col lg={12} className="mt-3">
                            <StaticImage src="../../../images/case-studies/gwc-logistics/style-frames-3.png" alt="GWC Logistics Explainer Video case study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={450}/>
                        </Col>                                                                        
                    </Row>
                    <div className="sub-content mt-5">
                        <p>In order to pre-visualize the animation, a storyboard with sketches of the scenes included in the video was shared with the client. Once it got approved, style frames were created.</p>
                        <p>Al Wukair Park is located in the heart of Qatar, next to the seaport and airport thereby facilitating the processes they offer. As a hub where MSMEs can come and set up their businesses, it offers a wide variety of activities.</p>
                        <p>To capture the scope of this, an isometric illustration style for the video was decided on during the video production process since it can provide a birds-eye view of the project to the viewers.</p>
                        <p>Check out the approved version of the <a href="/case-studies/gwc-logistics/GWC-storyboard.pdf" target="_blank">storyboard here.</a></p>
                    </div>                    
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default GwcStoryBoard
